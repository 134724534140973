import { css } from '@amedia/brick-tokens';

import { Payload } from '../../types/attributes';

const titleWrapper = css({
  display: 'block',
  variants: {
    opinion: {
      true: {
        background: 'rgba(18, 18, 18, 0.1)',
        marginTop: 'calc(-1 * $x3)',
        padding: '$x3 $x3 $x4',
        position: 'relative',
      },
    },
    image: {
      true: {
        borderRadius: '0 0 $teaserImage $teaserImage',
      },
      false: {
        borderRadius: '$teaserImage',
      },
    },
  },
});

const titleStyle = css({
  display: 'block',
  fontstyle: 'titleM',
  color: '$baseFg',
  fontFeatureSettings: '"ss02"',
  textDecoration: 'none',
  margin: 0,
  variants: {
    style: {
      video: {
        true: {
          fontstyle: 'titleM',
        },
      },
      gallery: {
        fontstyle: 'titleM',
      },
      review: {
        fontstyle: 'titleM',
      },
      feature: {
        fontstyle: 'titleFeatureM',
        fontWeight: '400',
        textAlign: 'center',
      },
      opinion: {
        fontstyle: 'titleFeatureM',
        fontWeight: '600',
        fontSize: '1.5rem',
      },
    },
  },
});

const reviewStyle = css({
  display: 'inline-flex',
  verticalAlign: 'text-top',

  '& > brick-icon > div > svg': {
    height: '24px',
    width: '24px',
  },
});

const arrowStyle = css({
  height: '11px',
  width: '16px',
  position: 'absolute',
  bottom: '-9px',
  left: '16px',
});

const getStyle = (data: typeof Payload) => {
  switch (true) {
    case !!data.review:
      return 'review';
    case data.opinion:
      return 'opinion';
    case !!data.gallery:
      return 'gallery';
    case data.feature:
      return 'feature';
    case !!data.video:
      return 'video';
  }
};

const Title = (data: typeof Payload) => {
  const renderScore = () =>
    `<div class="${reviewStyle()}"> 
      <brick-icon 
        iconId="dice-${data.review}"
        iconText="Review score"
        iconTheme="alt"
      </brick-icon> 
    </div>`;

  const renderReview = () => (data.review && renderScore()) || '';

  const renderArrow = () =>
    (data.opinion &&
      `<img class="${arrowStyle()}" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2MTIiIGhlaWdodD0iNDQ4Ij4KICA8cGF0aCBmaWxsPSJyZ2JhKDE4LDE4LDE4LDAuMSkiIGQ9Ik02MTEuNSA4NS41YTkxNjMzLjkzMiA5MTYzMy45MzIgMCAwIDEtMTg2LjUgMzIzYy0yOC44OTEgMzQuODUyLTY1LjM5MSA0Ni42ODUtMTA5LjUgMzUuNS0xOS4yOTMtNi43My0zNS4xMjYtMTguMjMtNDcuNS0zNC41YTM5MDUyLjcxOSAzOTA1Mi43MTkgMCAwIDEtMTg2LjUtMzIzYzE3Ni40OTktMSAzNTMuMTY2LTEuMzMzIDUzMC0xWiIvPgo8L3N2Zz4=" aria-hidden="true" width="100%"/>`) ||
    '';

  return `
    <div class="${titleWrapper({
      opinion: data.opinion,
      image: data.image.length > 0,
      review: !!data.review,
    })}">
     
      <h2 data-type="title" class="${titleStyle({ style: getStyle(data) })}">
      ${renderReview()}
      ${data.title}</h2>
    ${renderArrow()}
    </div>
  `;
};

export default Title;
