import { css } from '@amedia/brick-tokens';

export const imageStyle = css({
  display: 'block',
  aspectRatio: 'var(--aspect-ratio, initial)',
  overflow: 'hidden',

  '> img': {
    height: '100%',
    width: '100%',
    objectPosition: 'center',
    objectFit: 'cover',
  },

  variants: {
    opinion: {
      true: {
        '> img': {
          borderRadius: '$teaserImage $teaserImage 0 0',
        },
      },
    },
    feature: {
      true: {
        gridArea: 'image',

        '> img': {
          borderRadius: '$teaserImage',
        },
      },
    },
  },
});

export const galleryWrapStyle = css({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gridTemplateRows: 'repeat(2, 1fr)',
  '--brick-image-height': 'auto',
  '--brick-image-width': '100%',
  '--brick-image-objectfit': 'cover',
  'brick-image-v3': {
    position: 'relative',
    paddingBottom: '100%',
  },
  'brick-image-v3:nth-child(1)': {
    gridColumnStart: '1',
    gridColumnEnd: 'span 2',
    gridRowStart: '1',
    gridRowEnd: 'span 2',
  },
  'brick-image-v3:nth-child(2)': {
    gridColumnStart: '3',
    gridColumnEnd: '3',
    gridRowStart: '1',
    gridRowEnd: '1',
  },
  'brick-image-v3:nth-child(3)': {
    gridColumnStart: '3',
    gridColumnEnd: '3',
    gridRowStart: '2',
    gridRowEnd: '2',
  },
  'brick-image-v3 > img': {
    position: 'absolute',
    height: '100%',
    width: '100%',
    borderRadius: '$teaserImage',
  },
});
