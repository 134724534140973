import { css } from '@amedia/brick-tokens';

import { Payload } from '../../types/attributes';

const opinionWrapper = css({
  display: 'flex',
  alignItems: 'center',
  gap: '$x2',
  marginTop: '$x4',
  marginBottom: '$x6',
});

const authorImageStyle = css({
  height: '40px',
  width: '40px',
  objectFit: 'cover',
  borderRadius: '9999px',
});

const authorTextWrapper = css({
  display: 'flex',
  flexDirection: 'column',
});

const authorNameStyle = css({
  margin: '$none',
  fontstyle: 'baseSubheadS',
  fontStyle: 'normal',
  color: '$baseFg',
});

export const opinion = (data: typeof Payload) => {
  const {
    authorImage,
    authorName,
    authorDescription,
    name: publicationName,
    opinion,
  } = data;
  if (!opinion) return '';
  const name = authorName ? authorName : publicationName;
  const text = `Av: ${[name, authorDescription].filter(Boolean).join(', ')}`;

  return /* html */ `
<div class="${opinionWrapper()}">
    <!-- We do not have access to author image yet -->
    <!-- <img class="${authorImageStyle()}" src="${authorImage}" alt="${authorName}"> -->
    <div class="${authorTextWrapper()}">
        <cite class="${authorNameStyle()}">${text}</cite>
    </div>
</div>
`;
};
