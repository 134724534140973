/* These are all the attributes we expect to find on the element */
export const Payload = {
  altText: '',
  aoi: '',
  articleId: '',
  authorDescription: '',
  authorImage: '',
  authorName: '',
  backgroundColor: '',
  feature: false,
  gallery: '',
  image: '',
  imageRatio: '',
  link: '',
  logo: '',
  menuOnClickEventName: '',
  publicationOnHoverEventName: '',
  name: '',
  opinion: false,
  playerId: '',
  pretitle: '',
  publication: '',
  published: '',
  readEstimate: '',
  review: '',
  imagesizes: '',
  srcset: '',
  siteId: '',
  small: false,
  tags: '',
  testId: '',
  title: '',
  variantId: '',
  isVideo: false,
  video: '',
  videoId: '',
  countdown: '',
};
